import React from "react";
import "./ServicesComponent.css";

export const ServicesComponent = ({ img, header, text }) => {
    return (
        <div className="services-content-left">
            <img src={img} alt="logo1" />
            <h2>{header}</h2>
            <span>{text}</span>
        </div>
    );
};
