import React from "react";
import "./About.css";
import Logo from "../../Images/Layer_2.webp";

export const About = () => {
  return (
    <div className="about-container" id="about">
      <div className="about-left-content">
        <span style={{ color: "#f2c999" }}>About</span>{" "}
        <span style={{ color: "#fff" }}>Hooves Mover</span>
        <img src={Logo} alt="logo" />
      </div>
      <div className="about-right-content">
        <p>
          Welcome to Hooves Mover, where we revolutionize horse transport with
          our state-of-the-art mobile app. Our mission is to ensure complete
          safety and provide live tracking, so you can monitor your horse's
          journey in real-time.
        </p>
        <p>
          At Hooves Mover, we prioritize your horse’s well-being and your peace
          of mind, making us your trusted partner for all horse transport needs.
          Experience the future of horse transportation with us!
        </p>
      </div>
    </div>
  );
};
