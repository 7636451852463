import React from "react";
import Logo from "../../../Images/Logo.webp";
import "./MainLogoComponent.css";
import { Link } from "react-router-dom";

export const MainLogoComponent = () => {
    return (
        <Link to="/" className="logo">
            <img src={Logo} alt="logo" className="logo" />
        </Link>
    );
};
