import React, { useState } from "react";
import "./Nav.css";
import { MainLogoComponent } from "../common/MainLogoComponent/MainLogoComponent";

export const Nav = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLinkClick = () => {
        setMenuOpen(false);
    };

    return (
        <div className="nav-bar-wrapper">
            <div className={`nav-bar-container ${menuOpen ? "open" : ""}`}>
                <MainLogoComponent />
                <div className="burger-menu" onClick={handleMenuToggle}>
                    {menuOpen ? "✕" : "☰"}
                </div>
                <div className={`nav-bar-links ${menuOpen ? "open" : ""}`}>
                    <a href="#about" onClick={handleLinkClick}>
                        About us
                    </a>
                    <a href="#services" onClick={handleLinkClick}>
                        Our services
                    </a>
                    <a href="#contact" onClick={handleLinkClick}>
                        Contact us
                    </a>
                </div>
            </div>
        </div>
    );
};
