import React from "react";
import "./Contact.css";
import Logo from "../../Images/contactUsLogo.webp";

export const Contact = () => {
  return (
    <div className="contact-container" id="contact">
      <div className="contact-content">
        <form className="contact-form">
          <div className="contact-form-container">
            <div className="contact-form-header">
              <h2>Get in touch with us</h2>
              <div
                className="contact-form-inputs"
                style={{
                  marginTop: "1rem",
                }}
              >
                <span>Name</span>
                <input
                  type="text"
                  placeholder="Full Name"
                  className="contact-form-input"
                />
              </div>
              <div className="contact-form-inputs">
                <span>Email</span>
                <input
                  type="email"
                  placeholder="Your Email"
                  className="contact-form-input"
                />
              </div>
              <div className="contact-form-input-message">
                <span>Message</span>
                <textarea
                  placeholder="Type your message here..."
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  className="contact-form-input-message-textarea"
                />
              </div>
              <div className="contact-form-button-container">
                <button type="submit" className="contact-form-button">
                  Send my message
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="contact-content-image-container">
          <img className="contact-content-image" src={Logo} alt="logo" />
          <div className="contact-content-image-overlay"></div>
        </div>
      </div>
    </div>
  );
};
