import React from "react";
import { About } from "../About/About";
import { ImageGroup } from "../ImageGroup/ImageGroup";
import { Services } from "../Services/Services";
import { Contact } from "../ContactUs/Contact";
import { PhonesSecond } from "../Phones2/PhonesSecond";
import { Phone } from "../Phone/Phone";
import { Banner } from "../Banner/Banner";
import { Helmet } from "react-helmet";

export const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Hooves Mover</title>
        <meta
          name="description"
          content="Hooves Mover is a mobile application that provides horse transport services. This privacy policy applies to the Hooves Mover app for mobile devices that was created by HOOVES MOVER ANIMALS TRANSPORT CO. L.L.C as a Commercial service."
        />
      </Helmet>
      <Banner />
      <About />
      <Phone />
      <PhonesSecond />
      <Services />
      <ImageGroup />
      <Contact />
    </>
  );
};
