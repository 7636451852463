import React from "react";
import "./Services.css";
import Logo from "../../Images/Layer_2.webp";
import Logo1 from "../../Images/Horse1.png";
import Logo2 from "../../Images/Horse2.png";
import Logo3 from "../../Images/Horse3.png";
import { ServicesComponent } from "../common/ServicesComponent/ServicesComponent";

export const Services = () => {
    const items = [
        {
            img: Logo1,
            header: "Track With Maps",
            text: "Allocate your horse’s destination, while we take care of the rest.",
        },
        {
            img: Logo2,
            header: "Secured Transportation",
            text: "Manage your horse's transportation securely, while we offer you the ultimate security standards.",
        },
        {
            img: Logo3,
            header: "Live Feed",
            text: "Stay updated with real-time footage, by checking up on your horse’s route at any time.",
        },
    ];
    return (
        <div className="services-container" id="services">
            <div className="services-header">
                <img src={Logo} alt="logo" />
                <span>Our Services</span>
            </div>
            <div className="services-content">
                {items.map((item, index) => (
                    <ServicesComponent
                        key={index}
                        img={item.img}
                        header={item.header}
                        text={item.text}
                    />
                ))}
            </div>
        </div>
    );
};
