import React from "react";
import "./Banner.css";
import { DownloadAppComponent } from "../common/DownloadAppButtonComponent/DownloadAppComponent";
import AppleDownload from "../../Images/appleD.png";
import GoogleDownload from "../../Images/googleplay.png";

export const Banner = () => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <div className="banner-text">
          <h1
            style={{
              marginBottom: "0px",
            }}
          >
            Ride with Trust, Arrive in Style
          </h1>
          <h1
            style={{
              marginTop: "0px",
            }}
          >
            Hooves Mover
          </h1>
          <h2>Download our App to get the full experience</h2>
        </div>
        <div className="banner-buttons">
          <DownloadAppComponent
            className={"apple"}
            img={AppleDownload}
            alt="AppleStore"
            text="Download on the App Store"
            color="rgba(0, 0, 0, 1)"
            backgroundColor="linear-gradient(180deg, #D4A166 0%, #F2C999 100%)"
            imgStyle={{ width: "20px", height: "24px" }}
            textStyle={{
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "21.13px",
            }}
            textClassName={"apple-text"}
          />
          <DownloadAppComponent
            className={"google"}
            img={GoogleDownload}
            alt="GooglePlay"
            text="Get it on Google Play"
            color="rgba(0, 0, 0, 1)"
            backgroundColor="rgba(255, 255, 255, 1)"
            imgStyle={{ width: "20px", height: "24px" }}
            textStyle={{
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "21.13px",
            }}
            textClassName={"google-text"}
          />
        </div>
      </div>
    </div>
  );
};
