import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { AppContainer } from "./components/AppContainer/AppContainer";
import { Nav } from "./components/Nav/Nav";
import { Footer } from "./components/Footer/Footer";
import { HomePage } from "./components/HomePage/HomePage";
import { TermsCondition } from "./components/TermsCondition/TermsCondition";
import { PrivacyPolicy } from "./components/PrivacyPolicy/PrivacyPolicy";
import ScrollToTop from "./components/ScrollTop/ScrollTop";

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Nav />
            <AppContainer>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route
                        path="/terms-conditions"
                        element={<TermsCondition />}
                    />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                </Routes>
                <Footer />
            </AppContainer>
        </BrowserRouter>
    );
}

export default App;
