import React from "react";
import "./DownloadAppComponent.css";

export const DownloadAppComponent = ({
    backgroundColor,
    color,
    text,
    img,
    alt,
    textStyle,
    imgStyle,
    className,
    textClassName,
}) => {
    return (
        <button
            className={`download-app-button ${className}`}
            style={{ background: backgroundColor, color }}
        >
            <img src={img} alt={alt} style={imgStyle} />
            <span style={textStyle} className={textClassName}>
                {text}
            </span>
        </button>
    );
};
