import React from "react";
import "./Footer.css";
import email from "../../Images/email.webp";
import location from "../../Images/location.webp";
import appStore from "../../Images/appStore.webp";
import googlePlay from "../../Images/googlePlay.webp";
import { Link } from "react-router-dom";
import { MainLogoComponent } from "../common/MainLogoComponent/MainLogoComponent";

export const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="footer-content-container">
          <MainLogoComponent />
          <div className="footer-contact-info">
            <div className="footer-contact-info-item">
              <img
                className="footer-contact-info-icon"
                src={email}
                alt="email"
              />
              <a
                href="mailto:info@hoovesmover.com"
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}
              >
                <span className="footer-contact-info-item-text">
                  info@hoovesmover.com
                </span>
              </a>
            </div>
            <div className="footer-contact-info-item">
              <img
                className="footer-contact-info-icon"
                src={location}
                alt="location"
              />
              <span
                className="footer-contact-info-item-text"
                style={{
                  cursor: "auto",
                }}
              >
                United Arab Emirates
              </span>
            </div>
          </div>
          <div className="footer-app-links">
            <h2>Available on</h2>
            <div className="footer-app-links-container">
              <img src={appStore} alt="appStore" />
              <img src={googlePlay} alt="googlePlay" />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-private">
        <Link to="/terms-conditions">Terms & Conditions</Link>|
        <Link to="/privacy-policy">Privacy Policy</Link>|
        <span>© {new Date().getFullYear()} Hooves Mover</span>
      </div>
    </div>
  );
};
