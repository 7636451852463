import React from "react";
import "./PhonesSecond.css";

export const PhonesSecond = () => {
  return (
    <div className="phones-second-container">
      <div className="phones-second-content" />
    </div>
  );
};
