import React from "react";
import "./ImageGroup.css";
import horse1 from "../../Images/Horse1.webp";
import horse2 from "../../Images/Horse2.webp";
import horse3 from "../../Images/Horse3.webp";
import { ImageGroupComponent } from "../common/ImageGroupComponent/ImageGroupComponent";

export const ImageGroup = () => {
    const images = [horse1, horse2, horse3];
    return (
        <div className="ImageGroup">
            <div className="ImageGroupContainer">
                <div className="ImageGroupContent">
                    {images.map((img, index) => (
                        <ImageGroupComponent key={index} img={img} />
                    ))}
                </div>
            </div>
        </div>
    );
};
