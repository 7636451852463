import React from "react";
import "./Phone.css";

export const Phone = () => {
    return (
        <div className="phone-container">
            <div className="phone-content" />
        </div>
    );
};
